<!--
 * @Author: 陈剑伟
 * @Date: 2020-04-21 16:44:17
 * @LastEditTime: 2020-04-27 10:29:51
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \onesports-cloud-view-new\src\views\activiti\ModelList\index.vue
 -->
<template>
  <div class="model-wrapper">
    <div class="search-container">
       <el-form ref="form" :model="form" label-width="80px">
          <el-row>
            <el-col :span="6">
                <el-form-item label="名称">
                  <el-input clearable v-model="form.name"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="18" class="text-right">
                <el-button type="primary" @click="searchEvent" class="search-button">{{$t('search')}}</el-button>
                <el-button type="primary" @click="cleanForm" class="clean-button">{{$t('clean')}}</el-button>
            </el-col>
          </el-row>
       </el-form>
    </div>
    <div class="table-container">
      <el-table
				:data="tableData"
        :height="tableHeight"
				style="width: 100%"
        border
        :row-class-name="tableRowClassName"
				@selection-change="handleSelectionChange">
         <el-table-column
            type="index"
            align="center"
            label="序号"
            width="55">
          </el-table-column>
				<el-table-column
					type="selection"
          align="center"
					width="55">
				</el-table-column>
				<el-table-column
					prop="id"
          align="center"
					label="编号"
					min-width="300">
				</el-table-column>
				<el-table-column
					prop="name"
          align="center"
					label="名称"
					width="130">
				</el-table-column>
				<el-table-column
					prop="version"
          align="center"
					label="版本"
					width="100">
				</el-table-column>
				<el-table-column
					prop="deploymentId"
          align="center"
					label="发布编号"
					min-width="280">
				</el-table-column>
				<el-table-column
					prop="createTime"
          align="center"
					label="创建时间"
					width="190">
				</el-table-column>
				<el-table-column
					prop="lastUpdateTime"
          align="center"
					label="最后时间"
					min-width="220">
				</el-table-column>
        <el-table-column
          align="center"
          width="300px"
					:label="$t('option')">
          <template slot-scope="scope">
            <el-button size="mini" round type="primary" v-if="buttonAuth.includes('act:modelList:release')" @click="onModelListEdit(scope.row.id)">{{$t('edit')}}</el-button>
            <el-button size="mini" round type="primary" v-if="buttonAuth.includes('act:modelList:update')" @click="openDeleteDialog(scope.row.id, 9)">发布</el-button>
            <el-button size="mini" round type="primary" v-if="buttonAuth.includes('act:modelList:remove')" :disabled="!!scope.row.deploymentId" @click="openDeleteDialog(scope.row.id, 1)">{{$t('delete')}}</el-button>
          </template>
				</el-table-column>
			</el-table>
    </div>
    <div class="page-wrapper">
      <div class="">
        <el-button size="mini" round type="primary"  v-if="buttonAuth.includes('act:modelList:save')"  @click="handleAdd()">{{$t('add')}}</el-button>
        <el-button size="mini" round type="primary" v-if="buttonAuth.includes('act:modelList:remove')" :disabled="selectedArr.length===0"  @click="openDeleteDialog(selectedArr.map(item => item.id).join(','), 1)">{{$t('delete')}}</el-button>
      </div>
      <el-pagination
				@size-change="handleSizeChange"
				@current-change="handleCurrentChange"
				:current-page="form.pageNum"
				:page-sizes="[10, 20, 30, 40]"
				:page-size="form.pageSize"
				layout="total, sizes, prev, pager, next, jumper"
				:total="form.total">
			</el-pagination>
    </div>
    <!-- 添加/修改弹窗 -->
    <!-- <add-dialog ref="addDialog" :Language="Language" :show="addDialog.visible" :isEdit="addDialog.isEdit" @closeDialog="changeAddDialog"></add-dialog> -->
    <!-- 删除确认弹窗 -->
    <confirm-dialog ref="confirmDialog" :Language="Language" :show="confirmDialog.visible" :type="confirmDialog.type" @sureDialog="sureConfirmDialog" @closeDialog="changeConfirmDialog"></confirm-dialog>
     <el-dialog
      title="Activiti工作流在线流程设计编辑"
      :visible.sync="modelList.visible"
      :before-close="handleClose"
      center
      :close-on-click-modal="false"
      width="80%">
        <div style="position:relative;height: 100%;">
        <iframe
          id="iframe"
          :src="modelerUrl"
          frameborder="0"
          width="100%"
          height="720px"
          scrolling="auto"
        ></iframe>
        <Spin fix size="large" v-if="modelerLoading"></Spin>
      </div>
     </el-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import ModelSearchClass from "./modelSearchClass";
import apis from '@/apis'
import mixin from '@/mixins/index'
// import addDialog from './dialog/addDialog.vue'
import confirmDialog from '@/components/confirmDialog.vue'

// const modelerBaseUrl = process.env.VUE_APP_MODEL_BASE_URL

export default {
  data() {
    return {
      form: new ModelSearchClass('form'),
      confirmDialog: {
        visible: false,
        type: 1, //1:删除 9：发布
        data: {},
      },
      tableData: [],
      modelerUrl: "",
      modelerLoading: "",
      modelList: {
        visible: false,
      },
    }
  },
  mixins: [mixin],
  //部件
  components: {
    // addDialog,
    confirmDialog,
  },
  //静态
  props: {},
  //对象内部的属性监听，也叫深度监听
  watch: {},
  //属性的结果会被缓存，除非依赖的响应式属性变化才会重新计算。主要当作属性来使用；
  computed: {
    ...mapState(['Language']),
  },
  //方法表示一个具体的操作，主要书写业务逻辑；
  methods: {
    onModelListEdit(id) {
      this.modelList.visible = true
      this.modelerUrl = `${apis.editModel}?id=${id}`
    },
    handleAdd() {
      this.modelList.visible = true
      this.modelerUrl = `${apis.newModel}?time=${new Date().getTime()}`
    },
    /**
     * 新增弹窗
     */
    handleClose() {
      this.modelList.visible = false
    },
    /**
     * @function 改变确认/删除弹窗的状态
     * @param {Boolean} dialogStatus 是否展示当前弹窗
     * @param {Number} type 打开的弹窗类型，1为删除确认
     */
    changeConfirmDialog(dialogStatus, type = 1) {
      this.confirmDialog.type = type
      this.confirmDialog.visible = dialogStatus
      if (!dialogStatus) {
        this.confirmDialog.data.ids = ''
        this.searchEvent()
      }
    },
    /**
     * @function 当确认弹窗点击确定的时候触发
     */
    sureConfirmDialog() {
      // 如果一个页面有多个弹窗，视情况决定用if语句还是二元表达式对api进行判断
      if (this.confirmDialog.type === 1) {
        this.modelDelete()
      } else {
        this.modelPublish()
      }
    },
    /**
     * 删除和发布点击确认
     * id: 对应的id值
     * type 1：删除 9:发布
     */
    openDeleteDialog(id, type) {
      this.confirmDialog.type = type
      this.confirmDialog.data = id
      this.changeConfirmDialog(true, type)
    },
    /**
     * 删除
     */
    modelDelete() {
      this.$http.post(`${apis.modDelsRemove}?ids=${this.confirmDialog.data}`).then((res) => {
      if (res.data.code === 0) {
          this.$refs.confirmDialog.closeDialog()
          this.$message.success("删除成功!")
          this.searchData()
        }
      })
    },
    /**
     * 发布
     */
    modelPublish() {
      this.$http.post(`${apis.modelsDeploy}/${this.confirmDialog.data}`).then((res) => {
      if (res.data.code === 0) {
          this.$refs.confirmDialog.closeDialog()
          this.$message.success("发布成功!")
          this.searchData()
        }
      })
    },
    /**
     * @function 搜索方法
     */
    searchData() {
      this.$http.get(apis.modelsList, {
        params: this.form,
      }).then((res) => {
        if (res.data.code === 0) {
          this.tableData = res.data.rows
          this.form.total = res.data.total
        }
      })
    },
    /**
     * @function 清空搜索栏
     */
    cleanForm() {
      this.form = new ModelSearchClass('form')
      this.$nextTick(() => {
        this.searchEvent()
      })
    },
  },
  //请求数据
  created() {},
  mounted() {},
};
</script>

<style lang="less" scoped>
@import "../../../assets/styles/mixin.scoped.less";
.search-container {
  margin-top: 20px;
}
.page-wrapper {
  margin-top: 20px;
}
</style>
<style lang="less">
@import "../../../assets/styles/mixin.less";
.text-right {
  text-align: right;
}
</style>
